import React from 'react'

type Props = {
  completionPercentage: number
}
export const ProgressBar: React.FC<Props> = ({ completionPercentage }) => {
  // we always show at least 2% completion so users can understand it is a progress bar (otherwise it would be just an empty rectangle)
  const adjustedCompletionPercentage = Math.max(completionPercentage, 0.02)
  const cssPercentage = (adjustedCompletionPercentage * 100).toFixed(2) + '%'
  return (
    <>
      <div className="flex flex-row w-full rounded-full h-1 mt-4" style={{ background: '#f6f2ec' }}>
        <div
          className="h-1 rounded-full"
          style={{
            width: cssPercentage,
            background: '#000',
            transition: 'width 0.5s ease-in-out',
          }}
        ></div>
      </div>
    </>
  )
}
