/* tslint:disable */
/* eslint-disable */
/**
 * lead-tracking
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface EventIn
 */
export interface EventIn {
  /**
   *
   * @type {string}
   * @memberof EventIn
   */
  source: string
  /**
   *
   * @type {string}
   * @memberof EventIn
   */
  event_name: string
  /**
   *
   * @type {string}
   * @memberof EventIn
   */
  quiz_id?: string
  /**
   *
   * @type {string}
   * @memberof EventIn
   */
  zoe_lead_id?: string
  /**
   *
   * @type {string}
   * @memberof EventIn
   */
  quiz_email?: string
  /**
   *
   * @type {string}
   * @memberof EventIn
   */
  checkout_email?: string
  /**
   *
   * @type {string}
   * @memberof EventIn
   */
  facebook_id?: string
  /**
   *
   * @type {object}
   * @memberof EventIn
   */
  data?: object
}
/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>
}
/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<string>}
   * @memberof ValidationError
   */
  loc: Array<string>
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string
}

/**
 * DataConsolidationApi - axios parameter creator
 * @export
 */
export const DataConsolidationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Match Events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matchEvents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/data-consolidation/match-events`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DataConsolidationApi - functional programming interface
 * @export
 */
export const DataConsolidationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DataConsolidationApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Match Events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async matchEvents(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.matchEvents(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * DataConsolidationApi - factory interface
 * @export
 */
export const DataConsolidationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DataConsolidationApiFp(configuration)
  return {
    /**
     *
     * @summary Match Events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    matchEvents(options?: any): AxiosPromise<any> {
      return localVarFp.matchEvents(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * DataConsolidationApi - object-oriented interface
 * @export
 * @class DataConsolidationApi
 * @extends {BaseAPI}
 */
export class DataConsolidationApi extends BaseAPI {
  /**
   *
   * @summary Match Events
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataConsolidationApi
   */
  public matchEvents(options?: AxiosRequestConfig) {
    return DataConsolidationApiFp(this.configuration)
      .matchEvents(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * LeadEventApi - axios parameter creator
 * @export
 */
export const LeadEventApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Append
     * @param {EventIn} eventIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    append: async (eventIn: EventIn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'eventIn' is not null or undefined
      assertParamExists('append', 'eventIn', eventIn)
      const localVarPath = `/lead-event/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(eventIn, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * LeadEventApi - functional programming interface
 * @export
 */
export const LeadEventApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LeadEventApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Append
     * @param {EventIn} eventIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async append(
      eventIn: EventIn,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.append(eventIn, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * LeadEventApi - factory interface
 * @export
 */
export const LeadEventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = LeadEventApiFp(configuration)
  return {
    /**
     *
     * @summary Append
     * @param {EventIn} eventIn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    append(eventIn: EventIn, options?: any): AxiosPromise<any> {
      return localVarFp.append(eventIn, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * LeadEventApi - object-oriented interface
 * @export
 * @class LeadEventApi
 * @extends {BaseAPI}
 */
export class LeadEventApi extends BaseAPI {
  /**
   *
   * @summary Append
   * @param {EventIn} eventIn
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LeadEventApi
   */
  public append(eventIn: EventIn, options?: AxiosRequestConfig) {
    return LeadEventApiFp(this.configuration)
      .append(eventIn, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
