import { InputField } from 'components/core/fields/InputField'
import { FormScreen } from 'components/screen-templates/FormScreen'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { SupportedCountries } from 'libs/geoLocation'
import React from 'react'
import * as Yup from 'yup'
import { ContextAwareText } from '../core/text/ContextAwareText'

export type LocationSignUpScreenProps = {
  title: string
  description: string
  descriptionBelowForm: string
  buttonText: string
  crmTag: string
}

type Props = LocationSignUpScreenProps & {
  onNextClick: onNextClickType
  onBackClick: onBackClickCallback
  quizCountry?: SupportedCountries
}

export const LocationSignUpScreen: React.FC<Props> = ({
  title,
  description,
  descriptionBelowForm,
  buttonText,
  crmTag,
  onBackClick,
  onNextClick,
}) => {
  return (
    <FormScreen
      screenTitle={title}
      description={<ContextAwareText text={description} />}
      descriptionBelowForm={<ContextAwareText text={descriptionBelowForm} />}
      buttonText={buttonText}
      form={{
        body: <InputField placeholder="Your email*" name="email" type="email" />,
        // needed to prevent React "uncontrolled" error showing
        initialValues: { email: '', crmTag },
        validationSchema: Yup.object({
          email: Yup.string()
            .email('Please enter a valid email address')
            .required('Please enter a valid email address'),
        }),
      }}
      onNextClick={onNextClick}
      onBackClick={onBackClick}
    />
  )
}
