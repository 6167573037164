import React, { useEffect } from 'react'
import { useField } from 'formik'

type Option = {
  value: string
  label: string
}

type ToggleProps = {
  leftOption: Option
  rightOption: Option
  name: string // needed for useField(props)
  onToggle: (value: string) => void
}

export const Toggle: React.FC<ToggleProps> = (props) => {
  const [field, , helpers] = useField(props)
  const { setValue } = helpers

  const clickHandler = async () => {
    if (field.value === props.rightOption.value) {
      setValue(props.leftOption.value)
    } else {
      setValue(props.rightOption.value)
    }
  }

  useEffect(() => {
    props.onToggle(field.value as string)
  }, [field.value])

  return (
    <div className="toggle">
      <span className={field.value === props.leftOption.value ? 'toggle-active-option' : undefined}>
        {props.leftOption.label}
      </span>
      <input id="switch" type="checkbox" checked={field.value === props.rightOption.value} onChange={clickHandler} />
      <label htmlFor="switch">Toggle</label>
      <span className={field.value === props.rightOption.value ? 'toggle-active-option' : undefined}>
        {props.rightOption.label}
      </span>
    </div>
  )
}
