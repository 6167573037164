import { HeightUnitEnum, WeightUnitEnum } from 'clients/quiz-service'
import { Quizzes } from 'redux/quiz/types'
import { convertFtAndInchesToCm } from './convertHeights'
import { kgsGraphs, lbsGraphs, WeightGraphData } from './weightGraphsData'

export type WeightLossData = {
  weightLossGraph: string | null
  averageWeightLoss: number
  highWeightLoss: number
  weightUnit: WeightUnitEnum
}

const findWeightGraph = (
  weightGraph: WeightGraphData[],
  height_cm: number,
  weight: number,
  weightUnit: WeightUnitEnum,
): WeightLossData | null => {
  const weightLossGraphData = weightGraph.find((g) => {
    const withinHeight = height_cm >= g.heightMin && height_cm <= g.heightMax
    const withinWeight = weight >= g.weightMin && weight <= g.weightMax
    return withinHeight && withinWeight
  })
  if (weightLossGraphData) {
    const { weightLossGraph, averageWeightLoss, highWeightLoss } = weightLossGraphData
    return { weightLossGraph, averageWeightLoss, highWeightLoss, weightUnit }
  } else return null
}

export const getWeightLossData = (quiz: Quizzes): WeightLossData | null => {
  const { height_cm, height_ft, height_in, height_unit, weight_lbs, weight_kg, weight_unit } = quiz
  const heightInCm = height_unit === HeightUnitEnum.cm ? height_cm : convertFtAndInchesToCm(height_ft, height_in)

  if (weight_lbs && weight_unit === WeightUnitEnum.lbs) {
    return findWeightGraph(lbsGraphs, heightInCm, weight_lbs, WeightUnitEnum.lbs)
  }

  if (weight_kg && weight_unit === WeightUnitEnum.kg) {
    return findWeightGraph(kgsGraphs, heightInCm, weight_kg, WeightUnitEnum.kg)
  }
  return null
}
