import axios, { AxiosError } from 'axios'

interface RetryConfig {
  maxRetries: number
  retryDelay: number
  retryableErrors?: string[]
}

const defaultRetryConfig: RetryConfig = {
  maxRetries: 3,
  retryDelay: 300,
  retryableErrors: ['ECONNABORTED', 'ETIMEDOUT', 'ERR_NETWORK'],
}

export async function requestWithRetry<T>(
  apiCall: () => Promise<T>,
  retryConfig: Partial<RetryConfig> = {},
): Promise<T> {
  const config = { ...defaultRetryConfig, ...retryConfig }
  let lastError: Error | null = null

  for (let attempt = 1; attempt <= config.maxRetries; attempt++) {
    try {
      return await apiCall()
    } catch (error) {
      lastError = error as Error

      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError

        if (!config.retryableErrors?.includes(axiosError.code as string) && axiosError.response) {
          throw error
        }
      }

      if (attempt === config.maxRetries) {
        throw lastError
      }

      await new Promise((resolve) => setTimeout(resolve, config.retryDelay))
    }
  }
  throw lastError
}
