import * as Sentry from '@sentry/react'
import axios from 'axios'
import queryString from 'query-string'

export enum SupportedCountriesEnum {
  'US' = 'US',
  'GB' = 'GB',
}
export type SupportedCountries = 'US' | 'GB'

export const geoLocationByIP = async (): Promise<string | undefined> => {
  try {
    const result = await axios('https://getipcountry.zoe.net')
    const country: string = result?.data?.toUpperCase()

    return country
  } catch (exception) {
    if ((exception as any).message == 'Network Error') {
      Sentry.captureException(new Error('GEO-IP lookup: Call to getipcountry.zoe.net failed with Network Error'))
    } else {
      Sentry.captureException(exception)
    }
  }
}

export const geoQuizCountry = async (countryCookie?: string): Promise<SupportedCountries | undefined> => {
  try {
    if (countryCookie && countryCookie in SupportedCountriesEnum) {
      return countryCookie as SupportedCountries
    }

    const { country: urlCountry } = queryString.parse(location.search)
    const country = typeof urlCountry === 'string' ? urlCountry.toUpperCase() : undefined

    if (country && country in SupportedCountriesEnum) {
      return country as SupportedCountries
    }

    const geoCountry = await geoLocationByIP()

    if (geoCountry === 'US' || geoCountry === 'GB') {
      return geoCountry
    }

    return undefined
  } catch (exception) {
    Sentry.captureException(exception)
    return undefined
  }
}
