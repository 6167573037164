import * as Sentry from '@sentry/react'
import React, { useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { track, TrackEvent } from 'libs/lead-tracking'
import { useCookies } from 'react-cookie'
import {
  COOKIE_DOMAIN_MAP,
  DEFAULT_COOKIE_OPTIONS,
  QUIZ_COUNTRY_COOKIE_NAME,
  QUIZ_EMAIL_COOKIE_NAME,
  QUIZ_ID_COOKIE_NAME,
  STAGE_COOKIE_DOMAIN,
  ZOE_LEAD_TRACKING_COOKIE_NAME,
} from 'components/constants'

export interface ZoeLeadContextProviderProps {
  source: string
  children: React.ReactNode
}

type ZoeLeadContextType = {
  zoeLeadId: string
  trackLead: (event: Partial<TrackEvent>) => void
}

export const ZoeLeadContext = React.createContext<ZoeLeadContextType>({ zoeLeadId: '', trackLead: () => undefined })
export const useZoeLead = (): ZoeLeadContextType => useContext(ZoeLeadContext)

ZoeLeadContext.displayName = 'ZoeLeadContext'

export const ZoeLeadContextProvider: React.FC<ZoeLeadContextProviderProps> = ({
  source,
  children,
}: ZoeLeadContextProviderProps) => {
  const [cookies, setCookie] = useCookies([
    ZOE_LEAD_TRACKING_COOKIE_NAME,
    QUIZ_ID_COOKIE_NAME,
    QUIZ_EMAIL_COOKIE_NAME,
    QUIZ_COUNTRY_COOKIE_NAME,
  ])

  // Key identifiers
  const [leadId] = useState<string>(cookies[ZOE_LEAD_TRACKING_COOKIE_NAME] || uuidv4())
  const [quizId] = useState<string>(cookies[QUIZ_ID_COOKIE_NAME] || '')
  const [quizEmail] = useState<string>(cookies[QUIZ_EMAIL_COOKIE_NAME] || '')
  const [quizCountry] = useState<string>(cookies[QUIZ_COUNTRY_COOKIE_NAME] || '')

  // UTM parameters (query string and set in cookies)
  const [queryString, setQueryString] = useState<string>('')

  // Setup useEffects
  // Save lead id cookie
  useEffect(() => {
    if (leadId && leadId !== cookies[ZOE_LEAD_TRACKING_COOKIE_NAME]) {
      setCookie(ZOE_LEAD_TRACKING_COOKIE_NAME, leadId, {
        ...DEFAULT_COOKIE_OPTIONS,
        domain: COOKIE_DOMAIN_MAP[window.location.hostname as keyof typeof COOKIE_DOMAIN_MAP] || STAGE_COOKIE_DOMAIN,
      })
    }
  }, [leadId, cookies, setCookie])

  // Store landing query string in provider state
  useEffect(() => {
    window.location.search && setQueryString(window.location.search)
  }, [])

  // Lead tracking methods
  // Helper methods
  const getProviderEventData = () => ({
    ...(quizCountry && { country: quizCountry }),
    ...(queryString && { queryString }),
  })

  const decorateEvent = (event: Partial<TrackEvent>): TrackEvent => {
    const providerEvent = {
      source,
      zoeLeadId: leadId,
      ...(quizId && { quizId }),
      ...(quizEmail && { quizEmail }),
    }

    return {
      ...providerEvent,
      ...event,
      data: {
        ...getProviderEventData(),
        ...event.data,
      },
    } as TrackEvent
  }

  // Main lead tracking method
  const trackLead = async (event: Partial<TrackEvent>) => {
    try {
      await track(decorateEvent(event))
    } catch (exception) {
      if (process.env.REACT_APP_ENV === 'production') {
        Sentry.captureException(exception)
      } else {
        throw exception
      }
    }
  }

  return <ZoeLeadContext.Provider value={{ zoeLeadId: leadId, trackLead }}>{children}</ZoeLeadContext.Provider>
}
