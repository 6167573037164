import React from 'react'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { Quizzes } from 'redux/quiz/types'
import { weightRange } from 'libs/weight/weightCalculations'
import { ExplanationTemplate } from 'components/screen-templates/ExplanationTemplate'
import { FormattedMessage, useIntl } from 'react-intl'

export type ZoeCanHelpScreenProps = {
  title: string
  topImage: string
  description: string
  buttonText: string
}

type Props = ZoeCanHelpScreenProps & {
  onNextClick: onNextClickType
  onBackClick?: onBackClickCallback
  quiz: Quizzes
}

export const ZoeCanHelpScreen: React.FC<Props> = ({
  title,
  description,
  buttonText,
  topImage,
  quiz,
  onNextClick,
  onBackClick,
}) => {
  const intl = useIntl()

  const hasWeightGoal = quiz.has_weight_goal === 'true'
  const goalStringId = hasWeightGoal ? 'quiz.zoe_can_help.weight_goal.v5' : 'quiz.zoe_can_help.health_goal.v5'

  const { gender, age_range, health_goal } = quiz
  const { lowerWeight, upperWeight } = weightRange(quiz)

  const goalTokens = {
    gender: intl.formatMessage({
      id: `quiz.gender.${gender}.display_plural`,
    }),
    ageRange: intl.formatMessage({
      id: `quiz.age_range.${age_range}`,
    }),
    healthGoal: intl.formatMessage({
      id: `quiz.health_goal.${health_goal}.short.v5`,
    }),
    ...(hasWeightGoal && {
      lowerWeight: lowerWeight?.value,
      upperWeight: upperWeight?.value,
    }),
    unit: quiz.weight_unit || 'lbs',
  }

  return (
    <ExplanationTemplate
      image={topImage}
      imageAlt=""
      title={title}
      subTitle={<FormattedMessage id={goalStringId} values={goalTokens} />}
      body={description}
      cta={buttonText}
      onNextClick={onNextClick}
      onBackClick={onBackClick}
    />
  )
}
