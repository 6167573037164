import React, { useMemo, useState } from 'react'
import { FormScreen, FormValidationSchema, FormValues } from 'components/screen-templates/FormScreen'
import * as Yup from 'yup'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { Quizzes } from 'redux/quiz/types'
import { InputField } from 'components/core/fields/InputField'
import { lowerHealthyBMIWeightForHeightForUnit } from 'libs/weight/weightCalculations'
import { MAX_ALLOWED_WEIGHT_KG, MAX_ALLOWED_WEIGHT_LBS, WeightUnit } from 'libs/weight/types'
import { Toggle } from 'components/core/fields/Toggle'
import { Answer } from 'clients/quiz-service'
import { IntlShape, useIntl } from 'react-intl'
import { MIN_TEST_BMI } from 'libs/weight/bmi'

export type WeightScreenProps = {
  title: string
  description?: string
  buttonText?: string
}

type Props = WeightScreenProps & {
  onBackClick?: onBackClickCallback
  onNextClick: onNextClickType
  quiz: Quizzes
}
export const WeightScreen: React.FC<Props> = ({ title, description, buttonText, onNextClick, onBackClick, quiz }) => {
  const intl = useIntl()
  const defaultUnit: WeightUnit = 'lbs'
  const [selectedUnit, setSelectedUnit] = useState<string>(defaultUnit)

  const handleOnNextClick = (arg1?: FormValues | Answer[] | any) => {
    const args = arg1 || {}
    if (args.weight_unit === 'lbs') {
      onNextClick({ weight_unit: args.weight_unit, weight_lbs: args.weight_lbs })
    } else {
      onNextClick({ weight_unit: args.weight_unit, weight_kg: args.weight_kg })
    }
  }

  return (
    <FormScreen
      screenTitle={title}
      description={description}
      buttonText={buttonText}
      form={{
        body: (
          <>
            <Toggle
              name="weight_unit"
              leftOption={{ label: 'Metric', value: 'kg' }}
              rightOption={{ label: 'Imperial', value: 'lbs' }}
              onToggle={setSelectedUnit}
            />
            <InputField
              placeholder={`Weight in ${selectedUnit}*`}
              name={selectedUnit === 'lbs' ? 'weight_lbs' : 'weight_kg'}
              type="number"
              step="0.1"
            />
          </>
        ),
        validationSchema: validationSchema(intl, quiz),
        // needed to prevent React "uncontrolled" error showing
        initialValues: { weight_kg: '', weight_lbs: '', weight_unit: defaultUnit },
      }}
      onNextClick={handleOnNextClick}
      onBackClick={onBackClick}
    />
  )
}

const validationSchema = (intl: IntlShape, quiz: Quizzes): FormValidationSchema => {
  const minWeightLBS = useMemo(() => lowerHealthyBMIWeightForHeightForUnit(quiz, 'lbs', MIN_TEST_BMI), [quiz])
  const minWeightKG = useMemo(() => lowerHealthyBMIWeightForHeightForUnit(quiz, 'kg', MIN_TEST_BMI), [quiz])

  return Yup.object({
    weight_unit: Yup.string().required(),
    weight_lbs: Yup.number().when('weight_unit', {
      is: (weight_unit) => weight_unit === 'lbs',
      then: Yup.number()
        .required('Please enter your weight')
        .min(
          minWeightLBS.value,
          intl.formatMessage(
            { id: 'quiz.weight.validation.lower_bmi_limit' },
            { minBMI: MIN_TEST_BMI, minWeight: minWeightLBS.value, unit: 'lbs' },
          ),
        )
        .max(
          MAX_ALLOWED_WEIGHT_LBS,
          intl.formatMessage(
            { id: 'quiz.weight_validation.max_weight' },
            { maxWeight: MAX_ALLOWED_WEIGHT_LBS, unit: 'lbs' },
          ),
        ),
      otherwise: Yup.number(),
    }),
    weight_kg: Yup.number().when('weight_unit', {
      is: (weight_unit) => weight_unit === 'kg',
      then: Yup.number()
        .required('Please enter your weight')
        .min(
          minWeightKG.value,
          intl.formatMessage(
            { id: 'quiz.weight.validation.lower_bmi_limit' },
            { minBMI: MIN_TEST_BMI, minWeight: minWeightKG.value, unit: 'kg' },
          ),
        )
        .max(
          MAX_ALLOWED_WEIGHT_KG,
          intl.formatMessage(
            { id: 'quiz.weight_validation.max_weight' },
            { maxWeight: MAX_ALLOWED_WEIGHT_KG, unit: 'kg' },
          ),
        ),
      otherwise: Yup.number(),
    }),
  })
}
