import { BackIconButton } from 'components/core/buttons/BackIconButton'
import { Button } from 'components/core/buttons/Button'
import { InputField } from 'components/core/fields/InputField'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { ContextAwareText } from '../core/text/ContextAwareText'

export type EmailScreenProps = {
  title: string
  buttonText: string
  footerBody: string
}

type Props = EmailScreenProps & {
  onNextClick: onNextClickType
  onBackClick?: onBackClickCallback
}

export const EmailScreenMarketingConsent: React.FC<Props> = ({
  title,
  buttonText,
  footerBody,
  onNextClick,
  onBackClick,
}) => {
  const [showOptOutBox, setShowOptOutBox] = useState(false)

  const handleOptOutClick = () => {
    setShowOptOutBox(!showOptOutBox)
  }
  const optOutLink = (
    <a className={`opt-out-link ${!showOptOutBox && 'text-zoe-primary'}`} onClick={handleOptOutClick}>
      opt out
    </a>
  )

  return (
    <div>
      <div className="flex items-center justify-center align-center">
        {onBackClick && (
          <div className="flex items-center w-form-width-mobile sm:w-form-width-desktop sm:justify-start mt-8">
            <BackIconButton onClick={onBackClick} />
            <p className="text-zoe-primary" onClick={onBackClick} style={{ cursor: 'pointer' }}>
              Back
            </p>
          </div>
        )}
      </div>
      <main className="flex flex-row flex-1 w-full justify-center bg-background">
        <div
          className="flex w-form-width-mobile flex-col justify-between p-6 sm:w-form-width-desktop sm:justify-start"
          style={{ boxSizing: 'initial' }}
        >
          <h2 className="text-left">{title}</h2>
          <br />
          <Formik
            initialValues={{ email: '' }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email('Please enter a valid email address')
                .required('Please enter a valid email address'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              onNextClick(values)
              setSubmitting(true)
            }}
          >
            {({ isSubmitting, isValid, dirty }) => (
              <Form>
                <div className="mb-4">
                  <InputField placeholder={'Your email*'} name="email" type="email" />
                </div>
                <div className="mb-4mt-10 mb-12 text-center">
                  <Button type="submit" disabled={!(isValid && dirty) || isSubmitting}>
                    {buttonText}
                  </Button>
                </div>
                <p className="light text-base-secondary mt-6 sm:mt-8">
                  <ContextAwareText text={footerBody} additionalTokens={{ optOutLink }} />
                </p>

                {showOptOutBox && (
                  <div className="opt-out-checkbox-container">
                    <Field type="checkbox" name="marketingOptOut" id="marketingOptOut" className="opt-out-checkbox" />
                    <label htmlFor="marketingOptOut">I don&apos;t want ongoing emails including discounts</label>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </main>
    </div>
  )
}
