import { ExperimentNames } from './config/experiments.config'

const appEnv = process.env.REACT_APP_ENV || 'development'

export const __DEV__ = appEnv !== 'production'

export const GTM_ZOE_DATALAYER = 'zoeDataLayer'

export type AppConfig = {
  environment: string
  sentryDsn: string
  gtmV2Id: string
  fbPixelId: string
  quizServiceApiBaseUri: string
  leadTrackingApiBaseUri: string
  publicUrl: string
  zoeWebsiteBaseUri: string
  // list of experiments name that are enabled by default
  enabledExperiments?: ExperimentNames[]
}

const BASE = {
  sentryDsn: process.env.REACT_APP_SENTRY_DSN || '',
  gtmV2Id: process.env.REACT_APP_GTM_V2_ID || '',
  fbPixelId: process.env.REACT_APP_FB_PIXEL_ID || '',
  publicUrl: process.env.PUBLIC_URL || 'https://quiz.joinzoe.com',
  zoeWebsiteBaseUri: process.env.WEBSITE_URL || 'https://joinzoe.com',
  enabledExperiments: [],
}

const DEVELOPMENT: AppConfig = {
  ...BASE,
  environment: 'development',
  zoeWebsiteBaseUri: 'http://localhost:3000',
  quizServiceApiBaseUri: 'http://localhost',
  leadTrackingApiBaseUri: 'http://localhost',
  enabledExperiments: ['new-progress-bar'],
}

const STAGING: AppConfig = {
  ...BASE,
  environment: 'stage',
  quizServiceApiBaseUri: 'https://quiz.services.stage.zoe.net',
  leadTrackingApiBaseUri: 'https://lead-tracking.services.stage.zoe.net',
  enabledExperiments: ['new-progress-bar'],
}

const PRODUCTION: AppConfig = {
  ...BASE,
  environment: 'production',
  quizServiceApiBaseUri: 'https://quiz.services.prod.zoe.net',
  leadTrackingApiBaseUri: 'https://lead-tracking.services.prod.zoe.net',
}

export const appConfig: AppConfig =
  (appEnv === 'development' && DEVELOPMENT) ||
  (appEnv === 'stage' && STAGING) ||
  (appEnv === 'production' && PRODUCTION) ||
  DEVELOPMENT
