import React, { useCallback } from 'react'
import { ScreenProps } from '../types'
import { appConfig } from '../../appConfig'
import { Button } from '../core/buttons/Button'
import { Answer } from '../../redux/quiz/slice'
import { BackIconButton } from 'components/core/buttons/BackIconButton'

export interface MultiImagesInfoOnlyScreenProps extends ScreenProps {
  headerBar?: React.ReactNode
  screenTitle?: React.ReactNode
  descriptionAboveDynamicImageStatic?: React.ReactNode
  descriptionAboveDynamicImageDynamic?: React.ReactNode
  dynamicImage?: React.ReactNode // weight plan chart
  titleAboveImage1?: React.ReactNode
  descriptionAboveImage1?: React.ReactNode
  image1?: string
  image1Alt?: string
  descriptionBelowImage1Static?: React.ReactNode
  description2BelowImage1Static?: React.ReactNode
  image2?: string
  image2Alt?: string
  captionBelowImage2?: React.ReactNode
  isSeparatorBelowImage2Dynamic?: boolean
  descriptionBelowImage2Dynamic?: React.ReactNode
  descriptionBelowImage2Static?: React.ReactNode
  hideInterimCallToAction?: boolean
  showDivider: boolean
  dividerSectionText?: React.ReactNode
  otherImages?: string[]
  goalAnswersToBeSubmitted?: Answer[]
  buttonText: React.ReactNode
}

export const MultiImagesInfoOnlyScreen: React.FC<MultiImagesInfoOnlyScreenProps> = ({
  headerBar,
  screenTitle,
  descriptionAboveDynamicImageStatic,
  descriptionAboveDynamicImageDynamic,
  dynamicImage,
  titleAboveImage1,
  descriptionAboveImage1,
  image1,
  image1Alt,
  descriptionBelowImage1Static,
  description2BelowImage1Static,
  image2,
  image2Alt,
  captionBelowImage2,
  isSeparatorBelowImage2Dynamic,
  descriptionBelowImage2Dynamic,
  descriptionBelowImage2Static,
  hideInterimCallToAction,
  showDivider,
  dividerSectionText,
  otherImages,
  goalAnswersToBeSubmitted,
  buttonText,
  onNextClick,
  onBackClick,
}) => {
  const nextPageHandler = useCallback(() => {
    goalAnswersToBeSubmitted ? onNextClick(goalAnswersToBeSubmitted) : onNextClick()
  }, [onNextClick])

  const showInterimCallToAction = hideInterimCallToAction === undefined ? true : !hideInterimCallToAction

  return (
    <div>
      {headerBar}
      <div className="flex items-center justify-center align-center">
        {onBackClick && (
          <div className="flex items-center w-form-width-mobile sm:w-form-width-desktop sm:justify-start mt-8">
            <BackIconButton onClick={onBackClick} />
            <p className="text-zoe-primary" onClick={onBackClick} style={{ cursor: 'pointer' }}>
              Back
            </p>
          </div>
        )}
      </div>
      <main className="flex justify-center">
        <div className="mt-8 max-w-info-width-mobile sm:max-w-info-width-desktop text-left">
          <h2 className="light mb-6">{screenTitle}</h2>
          <div className="bg-surface-yellow p-24 rounded-lg text-center mb-4">
            {descriptionAboveDynamicImageStatic && (
              <p className="light text-base-secondary mb-2">{descriptionAboveDynamicImageStatic}</p>
            )}
            {descriptionAboveDynamicImageDynamic && (
              <div className="light text-base-secondary">{descriptionAboveDynamicImageDynamic}</div>
            )}
          </div>
          {dynamicImage && <div className="mb-8">{dynamicImage}</div>}

          {titleAboveImage1 && <h3 className="light mb-5">{titleAboveImage1}</h3>}
          {descriptionAboveImage1 && <p className="light text-base-secondary mb-8">{descriptionAboveImage1}</p>}
          {image1 && <img src={`${appConfig.publicUrl}${image1}`} alt={image1Alt} className="mb-4" />}

          <p className="light mb-6">{descriptionBelowImage1Static}</p>

          {description2BelowImage1Static && (
            <>
              <hr className="my-10" />
              <p className="light mb-6">{description2BelowImage1Static}</p>
            </>
          )}

          {image2 && <img src={`${appConfig.publicUrl}${image2}`} alt={image2Alt} className="mt-12" />}
          {captionBelowImage2 && <p className="light mt-8">{captionBelowImage2}</p>}

          {isSeparatorBelowImage2Dynamic && <hr className="my-10" />}
          {descriptionBelowImage2Dynamic && (
            <>
              <h3 className="light mt-10 text-base-secondary">Be {descriptionBelowImage2Dynamic}</h3>
              {image1 && showInterimCallToAction && (
                <div className="mt-10 mb-12">
                  <Button onClick={nextPageHandler}>{buttonText}</Button>
                </div>
              )}
            </>
          )}

          <p className="mt-12 sm:text-l light">{descriptionBelowImage2Static}</p>
          <div>{showDivider ? <hr className="my-8 border-background-tertiary" /> : ''}</div>
          <p className="light text-base-secondary">{dividerSectionText}</p>

          <div className="mt-10">
            {otherImages &&
              otherImages.map((image) => {
                return <img src={`${appConfig.publicUrl}${image}`} key={image} className="mb-8 flex-grow mx-auto" />
              })}
          </div>

          <div className="mt-10 mb-12">
            <Button onClick={nextPageHandler}>{buttonText}</Button>
          </div>
        </div>
      </main>
    </div>
  )
}
