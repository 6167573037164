import React, { useCallback } from 'react'
import { ScreenProps } from '../types'
import { Button } from '../core/buttons/Button'
import { Trustpilot } from 'components/trustpilot/Trustpilot'
import { BackIconButton } from 'components/core/buttons/BackIconButton'

export interface InfoOnlyScreenProps extends ScreenProps {
  headerBar?: React.ReactNode
  aboveTitle?: React.ReactNode
  screenTitleH2?: React.ReactNode
  screenTitle?: React.ReactNode
  descriptionAboveImage?: React.ReactNode
  image?: string
  imageAlt?: string
  captionBelowImage?: React.ReactNode
  headlineBelowImage?: React.ReactNode
  screenTitleH2BelowImage?: React.ReactNode
  descriptionBelowImage?: React.ReactNode
  screenTitleH2BeforeTrustpilot?: React.ReactNode
  footnoteBelowImage?: React.ReactNode
  footnote?: React.ReactNode
  buttonText?: React.ReactNode
  statisticCard?: React.ReactNode
  belowButton?: React.ReactNode
  trustpilot?: boolean
  showDivider?: boolean
}

export const InfoOnlyScreen: React.FC<InfoOnlyScreenProps> = ({
  aboveTitle,
  headerBar,
  screenTitle,
  screenTitleH2,
  descriptionAboveImage,
  image,
  imageAlt,
  captionBelowImage,
  headlineBelowImage,
  screenTitleH2BelowImage,
  descriptionBelowImage,
  screenTitleH2BeforeTrustpilot,
  footnoteBelowImage,
  footnote,
  trustpilot,
  showDivider,
  statisticCard,
  buttonText,
  onNextClick,
  onBackClick,
  belowButton,
}) => {
  const nextPageHandler = useCallback(() => onNextClick(), [onNextClick])

  return (
    <div>
      {headerBar}
      <div className="flex items-center justify-center align-center">
        {onBackClick && (
          <div className="flex items-center w-form-width-mobile sm:w-form-width-desktop sm:justify-start mt-8">
            <BackIconButton onClick={onBackClick} />
            <p className="text-zoe-primary" onClick={onBackClick} style={{ cursor: 'pointer' }}>
              Back
            </p>
          </div>
        )}
      </div>
      <main className="flex justify-center">
        <div className="mt-10 mx-6 max-w-info-width-mobile sm:max-w-info-width-desktop text-left">
          {aboveTitle && <div className="font-light mb-2">{aboveTitle}</div>}
          {screenTitleH2 && <h2 className="font-medium mb-6 text-3xl">{screenTitleH2}</h2>}
          <p className="text-base-secondary light mb-10 sm:leading-8">{screenTitle}</p>
          {descriptionAboveImage && <p className="light mb-8">{descriptionAboveImage}</p>}
          {image && <img src={`${image}`} alt={imageAlt} className="block mx-auto" />}
          {captionBelowImage && <p className="text-base-secondary light mb-10 sm:leading-8">{captionBelowImage}</p>}
          {headlineBelowImage && <h3 className="mt-12 mb-4 sm:leading-8 sm:mb-6">{headlineBelowImage}</h3>}
          {descriptionBelowImage && (
            <p className="text-base-secondary light mb-10 mt-6 sm:leading-8">{descriptionBelowImage}</p>
          )}
          {statisticCard && <div className="mt-8 mb-4">{statisticCard}</div>}
          {screenTitleH2BelowImage && (
            <h5 className="font-medium mb-10 mt-10 text-3xl sm:leading-8">{screenTitleH2BelowImage}</h5>
          )}
          {footnoteBelowImage && (
            <span className="light text-sm block mx-auto" style={{ width: '400px' }}>
              {footnoteBelowImage}
            </span>
          )}
          {showDivider && <div className="w-100 h-px bg-gray-200 my-10"></div>}
          {trustpilot && screenTitleH2BeforeTrustpilot && (
            <h5 className="font-medium mb-10 mt-10 text-3xl sm:leading-8">{screenTitleH2BeforeTrustpilot}</h5>
          )}
          {trustpilot && <Trustpilot />}
          {footnote && <p className="light mt-6">{footnote}</p>}
          <div className="mt-8 mb-12 text-center">
            <Button onClick={nextPageHandler}>{buttonText}</Button>
          </div>
          {belowButton}
        </div>
      </main>
    </div>
  )
}
