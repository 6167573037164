import ProgressBar from '@ramonak/react-progress-bar'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

type WeightProgressBarProps = {
  startWeight: number
  idealWeight: number
  weightUnit: string
  percentageOfGoal: number
}

export const WeightProgressBar: React.FC<WeightProgressBarProps> = ({
  startWeight,
  idealWeight,
  weightUnit,
  percentageOfGoal,
}) => {
  const [animatedGoal, setAnimatedGoal] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      if (animatedGoal !== percentageOfGoal) {
        setAnimatedGoal(animatedGoal + 1)
      }
    }, 5)
  }, [animatedGoal])

  const spanTextSize = percentageOfGoal === 100 ? 'xl' : '2xl'

  return (
    <div>
      <p className="mt-4 text-display-2xl font-light">
        {animatedGoal}
        <span className={`text-${spanTextSize} leading-relaxed`}>% of your weight goal</span>
      </p>
      <div className="mt-3">
        <ProgressBar
          completed={percentageOfGoal}
          bgColor="#C0D904"
          height="8px"
          borderRadius="3px"
          isLabelVisible={false}
          animateOnRender={true}
        />
      </div>
      <div className="flex justify-between mt-1 mb-2">
        <div className="text-xs leading-4 light">{`${startWeight}${weightUnit}`}</div>
        <div></div>
        <div className="text-xs leading-4 light">{`${idealWeight}${weightUnit}`}</div>
      </div>
      <p className="light">
        <FormattedMessage id="quiz.advice.weight_loss.statistic.weight.footer" />
      </p>
    </div>
  )
}
