/* tslint:disable */
/* eslint-disable */
/**
 * quiz-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration'
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
import type { RequestArgs } from './base'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base'

/**
 *
 * @export
 * @enum {string}
 */

export const AgeRangeEnum = {
  _20s: '20s',
  _30s: '30s',
  _40s: '40s',
  _50s: '50s',
  _60s: '60s',
  _70s: '70s',
  above_80: 'above_80',
  under_20: 'under_20',
} as const

export type AgeRangeEnum = typeof AgeRangeEnum[keyof typeof AgeRangeEnum]

/**
 *
 * @export
 * @interface Answer
 */
export interface Answer {
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  question_key: string
  /**
   *
   * @type {string}
   * @memberof Answer
   */
  answer: string
}
/**
 *
 * @export
 * @interface AnswersResponse
 */
export interface AnswersResponse {
  /**
   *
   * @type {LengthOfTimeEnum}
   * @memberof AnswersResponse
   */
  length_of_time?: LengthOfTimeEnum | null
  /**
   *
   * @type {MenopauseEnum}
   * @memberof AnswersResponse
   */
  menopause?: MenopauseEnum | null
  /**
   *
   * @type {string}
   * @memberof AnswersResponse
   */
  hear_about_us_other?: string | null
  /**
   *
   * @type {string}
   * @memberof AnswersResponse
   */
  hear_about_us_publication?: string | null
  /**
   *
   * @type {string}
   * @memberof AnswersResponse
   */
  hear_about_us_podcast?: string | null
  /**
   *
   * @type {string}
   * @memberof AnswersResponse
   */
  hear_about_us_online?: string | null
  /**
   *
   * @type {string}
   * @memberof AnswersResponse
   */
  hear_about_us_influencer?: string | null
  /**
   *
   * @type {HearAboutUsEnum}
   * @memberof AnswersResponse
   */
  hear_about_us?: HearAboutUsEnum | null
  /**
   *
   * @type {number}
   * @memberof AnswersResponse
   */
  pay_what_you_want?: number | null
  /**
   *
   * @type {SleepEnum}
   * @memberof AnswersResponse
   */
  sleep?: SleepEnum | null
  /**
   *
   * @type {HungerLevelEnum}
   * @memberof AnswersResponse
   */
  hunger_level?: HungerLevelEnum | null
  /**
   *
   * @type {EnergyEnum}
   * @memberof AnswersResponse
   */
  energy?: EnergyEnum | null
  /**
   *
   * @type {Array<WeightPlanPoint>}
   * @memberof AnswersResponse
   */
  weight_plan?: Array<WeightPlanPoint> | null
  /**
   *
   * @type {number}
   * @memberof AnswersResponse
   */
  goal_weight?: number | null
  /**
   *
   * @type {string}
   * @memberof AnswersResponse
   */
  goal_date?: string | null
  /**
   *
   * @type {WeightGoalTypeEnum}
   * @memberof AnswersResponse
   */
  goal_type?: WeightGoalTypeEnum | null
  /**
   *
   * @type {number}
   * @memberof AnswersResponse
   */
  ideal_weight_kg?: number | null
  /**
   *
   * @type {number}
   * @memberof AnswersResponse
   */
  ideal_weight_lbs?: number | null
  /**
   *
   * @type {number}
   * @memberof AnswersResponse
   */
  ideal_weight?: number | null
  /**
   *
   * @type {boolean}
   * @memberof AnswersResponse
   */
  has_weight_goal?: boolean | null
  /**
   *
   * @type {WeightUnitEnum}
   * @memberof AnswersResponse
   */
  weight_unit?: WeightUnitEnum | null
  /**
   *
   * @type {number}
   * @memberof AnswersResponse
   */
  weight_kg?: number | null
  /**
   *
   * @type {number}
   * @memberof AnswersResponse
   */
  weight_lbs?: number | null
  /**
   *
   * @type {number}
   * @memberof AnswersResponse
   */
  weight?: number | null
  /**
   *
   * @type {KnowYourWeightEnum}
   * @memberof AnswersResponse
   */
  know_your_weight?: KnowYourWeightEnum | null
  /**
   *
   * @type {QuizVersionEnum}
   * @memberof AnswersResponse
   */
  quiz_version?: QuizVersionEnum | null
  /**
   *
   * @type {HealthGoalEnum}
   * @memberof AnswersResponse
   */
  health_goal?: HealthGoalEnum | null
  /**
   *
   * @type {HeightUnitEnum}
   * @memberof AnswersResponse
   */
  height_unit?: HeightUnitEnum | null
  /**
   *
   * @type {number}
   * @memberof AnswersResponse
   */
  height_cm?: number | null
  /**
   *
   * @type {number}
   * @memberof AnswersResponse
   */
  height_in?: number | null
  /**
   *
   * @type {number}
   * @memberof AnswersResponse
   */
  height_ft?: number | null
  /**
   *
   * @type {AgeRangeEnum}
   * @memberof AnswersResponse
   */
  age_range?: AgeRangeEnum | null
  /**
   *
   * @type {SexEnum}
   * @memberof AnswersResponse
   */
  sex?: SexEnum | null
  /**
   *
   * @type {GenderEnum}
   * @memberof AnswersResponse
   */
  gender?: GenderEnum | null
}

/**
 *
 * @export
 * @enum {string}
 */

export const EnergyEnum = {
  never: 'never',
  sometimes: 'sometimes',
  often: 'often',
} as const

export type EnergyEnum = typeof EnergyEnum[keyof typeof EnergyEnum]

/**
 *
 * @export
 * @enum {string}
 */

export const GenderEnum = {
  female: 'female',
  male: 'male',
  pnts: 'pnts',
  other: 'other',
} as const

export type GenderEnum = typeof GenderEnum[keyof typeof GenderEnum]

/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>
}
/**
 *
 * @export
 * @enum {string}
 */

export const HealthGoalEnum = {
  ageing: 'ageing',
  diabetes: 'diabetes',
  gut_health: 'gut_health',
  heart_health: 'heart_health',
  high_blood_pressure: 'high_blood_pressure',
  mental_health: 'mental_health',
  other: 'other',
  strength: 'strength',
  brain_health: 'brain_health',
  immune_system: 'immune_system',
  aging: 'aging',
  energy: 'energy',
  sleep: 'sleep',
  mood: 'mood',
  hunger: 'hunger',
  digestion: 'digestion',
  menopause: 'menopause',
  healthy_weight: 'healthy_weight',
} as const

export type HealthGoalEnum = typeof HealthGoalEnum[keyof typeof HealthGoalEnum]

/**
 *
 * @export
 * @enum {string}
 */

export const HearAboutUsEnum = {
  online: 'online',
  influencer: 'influencer',
  referral: 'referral',
  publication: 'publication',
  search_engine: 'search_engine',
  ms_gut_shot_partnership: 'ms_gut_shot_partnership',
  ask_zoe_app: 'ask_zoe_app',
  daily_30: 'daily_30',
  waitrose: 'waitrose',
  tv_radio: 'tv_radio',
  zoe_study: 'zoe_study',
  podcast: 'podcast',
  zoe_podcast: 'zoe_podcast',
  other: 'other',
} as const

export type HearAboutUsEnum = typeof HearAboutUsEnum[keyof typeof HearAboutUsEnum]

/**
 *
 * @export
 * @enum {string}
 */

export const HeightUnitEnum = {
  cm: 'cm',
  ft: 'ft',
} as const

export type HeightUnitEnum = typeof HeightUnitEnum[keyof typeof HeightUnitEnum]

/**
 *
 * @export
 * @enum {string}
 */

export const HungerLevelEnum = {
  all_the_time: 'all_the_time',
  often: 'often',
  not: 'not',
} as const

export type HungerLevelEnum = typeof HungerLevelEnum[keyof typeof HungerLevelEnum]

/**
 *
 * @export
 * @enum {string}
 */

export const KnowYourWeightEnum = {
  true: 'true',
  false: 'false',
  approximately: 'approximately',
} as const

export type KnowYourWeightEnum = typeof KnowYourWeightEnum[keyof typeof KnowYourWeightEnum]

/**
 *
 * @export
 * @enum {string}
 */

export const LengthOfTimeEnum = {
  less_than_one_month: 'less_than_one_month',
  one_to_two_months: 'one_to_two_months',
  three_to_six_months: 'three_to_six_months',
  seven_to_twelve_months: 'seven_to_twelve_months',
  more_than_one_year: 'more_than_one_year',
} as const

export type LengthOfTimeEnum = typeof LengthOfTimeEnum[keyof typeof LengthOfTimeEnum]

/**
 *
 * @export
 * @interface LocationInner
 */
export interface LocationInner {}
/**
 *
 * @export
 * @enum {string}
 */

export const MenopauseEnum = {
  premenopausal: 'premenopausal',
  perimenopausal: 'perimenopausal',
  postmenopausal: 'postmenopausal',
  do_not_know: 'do_not_know',
  prefer_not_to_say: 'prefer_not_to_say',
} as const

export type MenopauseEnum = typeof MenopauseEnum[keyof typeof MenopauseEnum]

/**
 *
 * @export
 * @interface QuizAnswer
 */
export interface QuizAnswer {
  /**
   *
   * @type {string}
   * @memberof QuizAnswer
   */
  quiz_country?: string | null
  /**
   *
   * @type {string}
   * @memberof QuizAnswer
   */
  question_key: string
  /**
   *
   * @type {string}
   * @memberof QuizAnswer
   */
  answer: string
  /**
   *
   * @type {string}
   * @memberof QuizAnswer
   */
  user_id: string
  /**
   *
   * @type {string}
   * @memberof QuizAnswer
   */
  quiz_version: string
}
/**
 *
 * @export
 * @interface QuizAnswersRequest
 */
export interface QuizAnswersRequest {
  /**
   *
   * @type {string}
   * @memberof QuizAnswersRequest
   */
  user_id: string
  /**
   *
   * @type {string}
   * @memberof QuizAnswersRequest
   */
  quiz_version: string
  /**
   *
   * @type {Array<Answer>}
   * @memberof QuizAnswersRequest
   */
  answers: Array<Answer>
  /**
   *
   * @type {string}
   * @memberof QuizAnswersRequest
   */
  tag_id?: string | null
  /**
   *
   * @type {string}
   * @memberof QuizAnswersRequest
   */
  quiz_country?: string | null
}
/**
 *
 * @export
 * @interface QuizResponse
 */
export interface QuizResponse {
  /**
   *
   * @type {string}
   * @memberof QuizResponse
   */
  quiz_country: string
  /**
   *
   * @type {AnswersResponse}
   * @memberof QuizResponse
   */
  answers: AnswersResponse
}
/**
 *
 * @export
 * @enum {string}
 */

export const QuizVersionEnum = {
  V1: 'V1',
  V2: 'V2',
  v1: 'v1',
  v2: 'v2',
  v3: 'v3',
  v3b: 'v3b',
  v3c: 'v3c',
  v3d: 'v3d',
  v4: 'v4',
  v5: 'v5',
  v5b: 'v5b',
  v5c: 'v5c',
  no_test_trial: 'no-test-trial',
} as const

export type QuizVersionEnum = typeof QuizVersionEnum[keyof typeof QuizVersionEnum]

/**
 *
 * @export
 * @interface RegistrationBase
 */
export interface RegistrationBase {
  /**
   *
   * @type {string}
   * @memberof RegistrationBase
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof RegistrationBase
   */
  email: string
}
/**
 *
 * @export
 * @interface RegistrationRequest
 */
export interface RegistrationRequest {
  /**
   *
   * @type {string}
   * @memberof RegistrationRequest
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof RegistrationRequest
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof RegistrationRequest
   */
  quiz_version: string
  /**
   *
   * @type {string}
   * @memberof RegistrationRequest
   */
  tag_id?: string | null
  /**
   *
   * @type {boolean}
   * @memberof RegistrationRequest
   */
  check_waitlist?: boolean | null
  /**
   *
   * @type {string}
   * @memberof RegistrationRequest
   */
  crm_tag?: RegistrationRequestCrmTagEnum | null
  /**
   *
   * @type {string}
   * @memberof RegistrationRequest
   */
  health_goal?: string | null
  /**
   *
   * @type {boolean}
   * @memberof RegistrationRequest
   */
  marketing_consent: boolean
}

export const RegistrationRequestCrmTagEnum = {
  us_waitlist: 'us_waitlist',
  gb_waitlist: 'gb_waitlist',
} as const

export type RegistrationRequestCrmTagEnum = typeof RegistrationRequestCrmTagEnum[keyof typeof RegistrationRequestCrmTagEnum]

/**
 *
 * @export
 * @interface RegistrationResponse
 */
export interface RegistrationResponse {
  /**
   *
   * @type {RegistrationBase}
   * @memberof RegistrationResponse
   */
  user: RegistrationBase
  /**
   *
   * @type {boolean}
   * @memberof RegistrationResponse
   */
  on_waitlist?: boolean | null
}
/**
 *
 * @export
 * @enum {string}
 */

export const SexEnum = {
  female: 'female',
  male: 'male',
} as const

export type SexEnum = typeof SexEnum[keyof typeof SexEnum]

/**
 *
 * @export
 * @enum {string}
 */

export const SleepEnum = {
  easy: 'easy',
  okay: 'okay',
  trouble: 'trouble',
} as const

export type SleepEnum = typeof SleepEnum[keyof typeof SleepEnum]

/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<LocationInner>}
   * @memberof ValidationError
   */
  loc: Array<LocationInner>
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const WeightGoalTypeEnum = {
  maintenance: 'weight_maintenance',
  loss: 'weight_loss',
} as const

export type WeightGoalTypeEnum = typeof WeightGoalTypeEnum[keyof typeof WeightGoalTypeEnum]

/**
 *
 * @export
 * @interface WeightPlanPoint
 */
export interface WeightPlanPoint {
  /**
   *
   * @type {string}
   * @memberof WeightPlanPoint
   */
  date: string
  /**
   *
   * @type {number}
   * @memberof WeightPlanPoint
   */
  weight: number
}
/**
 *
 * @export
 * @enum {string}
 */

export const WeightUnitEnum = {
  kg: 'kg',
  lbs: 'lbs',
} as const

export type WeightUnitEnum = typeof WeightUnitEnum[keyof typeof WeightUnitEnum]

/**
 * QuizApi - axios parameter creator
 * @export
 */
export const QuizApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Add or update an answer to a question
     * @summary Answer
     * @param {QuizAnswer} quizAnswer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    answer: async (quizAnswer: QuizAnswer, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'quizAnswer' is not null or undefined
      assertParamExists('answer', 'quizAnswer', quizAnswer)
      const localVarPath = `/quiz/answer`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication GoogleOpenId required

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(quizAnswer, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Add or update a list of answers
     * @summary Answers
     * @param {QuizAnswersRequest} quizAnswersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    answers: async (
      quizAnswersRequest: QuizAnswersRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'quizAnswersRequest' is not null or undefined
      assertParamExists('answers', 'quizAnswersRequest', quizAnswersRequest)
      const localVarPath = `/quiz/answers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication GoogleOpenId required

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(quizAnswersRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Answers
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnswers: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getAnswers', 'userId', userId)
      const localVarPath = `/quiz/answers/{user_id}`.replace(`{${'user_id'}}`, encodeURIComponent(String(userId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication GoogleOpenId required

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Get Quiz
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuiz: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getQuiz', 'userId', userId)
      const localVarPath = `/quiz/{user_id}`.replace(`{${'user_id'}}`, encodeURIComponent(String(userId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication GoogleOpenId required

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * QuizApi - functional programming interface
 * @export
 */
export const QuizApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = QuizApiAxiosParamCreator(configuration)
  return {
    /**
     * Add or update an answer to a question
     * @summary Answer
     * @param {QuizAnswer} quizAnswer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async answer(
      quizAnswer: QuizAnswer,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.answer(quizAnswer, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['QuizApi.answer']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Add or update a list of answers
     * @summary Answers
     * @param {QuizAnswersRequest} quizAnswersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async answers(
      quizAnswersRequest: QuizAnswersRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.answers(quizAnswersRequest, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['QuizApi.answers']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Get Answers
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnswers(
      userId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnswersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAnswers(userId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['QuizApi.getAnswers']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @summary Get Quiz
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQuiz(
      userId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuizResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getQuiz(userId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath = operationServerMap['QuizApi.getQuiz']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * QuizApi - factory interface
 * @export
 */
export const QuizApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = QuizApiFp(configuration)
  return {
    /**
     * Add or update an answer to a question
     * @summary Answer
     * @param {QuizAnswer} quizAnswer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    answer(quizAnswer: QuizAnswer, options?: any): AxiosPromise<any> {
      return localVarFp.answer(quizAnswer, options).then((request) => request(axios, basePath))
    },
    /**
     * Add or update a list of answers
     * @summary Answers
     * @param {QuizAnswersRequest} quizAnswersRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    answers(quizAnswersRequest: QuizAnswersRequest, options?: any): AxiosPromise<any> {
      return localVarFp.answers(quizAnswersRequest, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Answers
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnswers(userId: string, options?: any): AxiosPromise<AnswersResponse> {
      return localVarFp.getAnswers(userId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Get Quiz
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuiz(userId: string, options?: any): AxiosPromise<QuizResponse> {
      return localVarFp.getQuiz(userId, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * QuizApi - object-oriented interface
 * @export
 * @class QuizApi
 * @extends {BaseAPI}
 */
export class QuizApi extends BaseAPI {
  /**
   * Add or update an answer to a question
   * @summary Answer
   * @param {QuizAnswer} quizAnswer
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuizApi
   */
  public answer(quizAnswer: QuizAnswer, options?: RawAxiosRequestConfig) {
    return QuizApiFp(this.configuration)
      .answer(quizAnswer, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Add or update a list of answers
   * @summary Answers
   * @param {QuizAnswersRequest} quizAnswersRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuizApi
   */
  public answers(quizAnswersRequest: QuizAnswersRequest, options?: RawAxiosRequestConfig) {
    return QuizApiFp(this.configuration)
      .answers(quizAnswersRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Answers
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuizApi
   */
  public getAnswers(userId: string, options?: RawAxiosRequestConfig) {
    return QuizApiFp(this.configuration)
      .getAnswers(userId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get Quiz
   * @param {string} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof QuizApi
   */
  public getQuiz(userId: string, options?: RawAxiosRequestConfig) {
    return QuizApiFp(this.configuration)
      .getQuiz(userId, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Register or update a user to a known user id
     * @summary Register
     * @param {RegistrationRequest} registrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register: async (
      registrationRequest: RegistrationRequest,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'registrationRequest' is not null or undefined
      assertParamExists('register', 'registrationRequest', registrationRequest)
      const localVarPath = `/user/register`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication GoogleOpenId required

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(registrationRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
  return {
    /**
     * Register or update a user to a known user id
     * @summary Register
     * @param {RegistrationRequest} registrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async register(
      registrationRequest: RegistrationRequest,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.register(registrationRequest, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['UserApi.register']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath)
    },
  }
}

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UserApiFp(configuration)
  return {
    /**
     * Register or update a user to a known user id
     * @summary Register
     * @param {RegistrationRequest} registrationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    register(registrationRequest: RegistrationRequest, options?: any): AxiosPromise<RegistrationResponse> {
      return localVarFp.register(registrationRequest, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   * Register or update a user to a known user id
   * @summary Register
   * @param {RegistrationRequest} registrationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public register(registrationRequest: RegistrationRequest, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .register(registrationRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
