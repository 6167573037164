import React from 'react'
import { LogoHeader } from 'components/screen-templates/LogoHeader'
import { StageProgressBar } from 'components/screen-components/StageProgressBar'
import { LegacyQuizScreen } from '../../config/types'
import { ProgressBar } from './ProgressBar'

type ProgressBarPosition = {
  currentStep: number
  currentStage: number
  stepsInStage: number
}

export type Stage = {
  name: string
  stageNumber: number
  startsAtIndex: number
  endsAtIndex: number
}

interface HeaderBarProps {
  currentPageIndex: number
  stages: Stage[]
  hideProgressBar: boolean
  screens: LegacyQuizScreen[]
  // can be deleted once experiment is over
  useNewProgressBar: boolean
}

const ProgressBarComponent: React.FC<HeaderBarProps> = ({ useNewProgressBar, screens, currentPageIndex, stages }) => {
  if (useNewProgressBar) {
    // we ignore conditional screens (showWhen) when calculating the progress bar, to give a better sense of progress - as there
    // are many optional screens that wouldn't be shown to the user and would make the progress bar misleading
    const remainingScreens = screens.slice(currentPageIndex + 1).filter((screen) => !screen.conditionalScreen).length
    const currentCompletion = currentPageIndex / Math.max(currentPageIndex + remainingScreens, 1)
    return <ProgressBar completionPercentage={currentCompletion} />
  }

  const progressBarStage = getProgressBarPosition(currentPageIndex, stages)
  return (
    <StageProgressBar
      currentStep={progressBarStage.currentStep}
      stepsInStage={progressBarStage.stepsInStage}
      currentStage={progressBarStage.currentStage}
      totalStages={stages.length}
    />
  )
}

export const HeaderBar: React.FC<HeaderBarProps> = (props) => {
  return (
    <>
      <LogoHeader hideBorder={props.useNewProgressBar} />
      {props.hideProgressBar || (
        <div className="flex content-center w-form-width-mobile sm:w-form-width-desktop sm:justify-start mx-auto">
          <ProgressBarComponent {...props} />
        </div>
      )}
    </>
  )
}

export const getProgressBarPosition = (currentPageIndex: number, stages: Stage[]): ProgressBarPosition => {
  const currentStage = stages.find(
    (stage) => stage.startsAtIndex <= currentPageIndex && stage.endsAtIndex >= currentPageIndex,
  )

  if (currentStage === undefined) return getProgressBarPosition(stages[stages.length - 1].endsAtIndex, stages)

  const stepsInStage = currentStage.endsAtIndex - currentStage.startsAtIndex + 1
  const currentStep = currentPageIndex - currentStage.startsAtIndex + 1

  return { currentStage: currentStage.stageNumber, stepsInStage: stepsInStage, currentStep: currentStep }
}
