import { appConfig } from 'appConfig'

// For WeightByDateHeading
export const ADVICE2_2X_RISK_DAYS_OFFSET = 17
export const ADVICE3_DEFAULT_DAYS_OFFSET_FROM_ADVICE2 = 14

// Cookies
export const ZOE_LEAD_TRACKING_COOKIE_NAME = 'cZoeLead'
export const QUIZ_ID_COOKIE_NAME = 'cUserId'
export const QUIZ_VERSION_COOKIE_NAME = 'cQuizVersion'
export const QUIZ_FLOW_COOKIE_NAME = 'cQuizFlow'
export const QUIZ_EMAIL_COOKIE_NAME = 'cUserEmail'
export const QUIZ_COUNTRY_COOKIE_NAME = 'cQuizCountry'
export const QUIZ_COMPLETE_COOKIE_NAME = 'cQuizComplete'

export const STAGE_COOKIE_DOMAIN = process.env.STAGE_COOKIE_DOMAIN || 'stage.joinzoe.com'
export const PROD_COOKIE_DOMAIN_JOINZOE = '.joinzoe.com'
export const PROD_COOKIE_DOMAIN = '.zoe.com'

export const COOKIE_DOMAIN_MAP = {
  localhost: 'localhost',
  'joinzoe.com': PROD_COOKIE_DOMAIN_JOINZOE,
  'my.joinzoe.com': PROD_COOKIE_DOMAIN_JOINZOE,
  'quiz.joinzoe.com': PROD_COOKIE_DOMAIN_JOINZOE,
  'bluepoop.joinzoe.com': PROD_COOKIE_DOMAIN_JOINZOE,
  'zoe.com': PROD_COOKIE_DOMAIN,
  'my.zoe.com': PROD_COOKIE_DOMAIN,
  'quiz.zoe.com': PROD_COOKIE_DOMAIN,
  'bluepoop.zoe.com': PROD_COOKIE_DOMAIN,
}

export const SECONDS_IN_A_DAY = 86400
export const FOUR_YEARS_IN_SECONDS = (4 * 365 + 1) * SECONDS_IN_A_DAY

export const QUIZ_ID_COOKIE_EXPIRY_DAYS = 30

export const DEFAULT_COOKIE_OPTIONS = {
  path: '/',
  maxAge: FOUR_YEARS_IN_SECONDS,
}

// Exit urls
export const GB_EXIT_PATH = `${appConfig.zoeWebsiteBaseUri}/whats-included?country=GB`
export const US_EXIT_PATH = `${appConfig.zoeWebsiteBaseUri}/whats-included?country=US`
