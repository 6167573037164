import React, { useCallback } from 'react'
import { ScreenProps } from '../types'
import { Button } from '../core/buttons/Button'
import { BackIconButton } from 'components/core/buttons/BackIconButton'
import { ContextAwareText } from '../core/text/ContextAwareText'

export interface TestimonialTemplateProps extends ScreenProps {
  descriptionBelowImage?: React.ReactNode
  title: string
  buttonText?: React.ReactNode
  testimonies: Testimony[]
}

export type Testimony = {
  text: string
  author: string
}

export const TestimonialTemplate: React.FC<TestimonialTemplateProps> = ({
  title,
  testimonies,
  buttonText,
  onNextClick,
  onBackClick,
}) => {
  const nextPageHandler = useCallback(() => onNextClick(), [onNextClick])

  return (
    <div>
      <div className="flex items-center justify-center align-center">
        {onBackClick && (
          <div className="flex items-center w-form-width-mobile sm:w-form-width-desktop sm:justify-start mt-8">
            <BackIconButton onClick={onBackClick} />
            <p className="text-zoe-primary" onClick={onBackClick} style={{ cursor: 'pointer' }}>
              Back
            </p>
          </div>
        )}
      </div>
      <main className="flex justify-center">
        <div className="mt-10 mx-6 max-w-info-width-mobile sm:max-w-info-width-desktop text-left">
          <h2 className="px-4 light mb-10">
            <ContextAwareText text={title} />
          </h2>

          {testimonies.map((testimony, index) => (
            <div key={index} className="bg-surface-teal-subtle p-24 rounded-lg text-left mb-8">
              <img src="/img/quiz/rating.png" className="block mb-4" />
              <h5 className="italic">“{testimony.text}”</h5>
              <p className="mt-2 text-sm">{testimony.author}</p>
            </div>
          ))}
          <div className="mt-8 mb-12 text-center">
            <Button onClick={nextPageHandler}>{buttonText}</Button>
          </div>
        </div>
      </main>
    </div>
  )
}
