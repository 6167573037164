import React from 'react'

import { appConfig } from 'appConfig'
import { Button } from 'components/core/buttons/Button'
import { Form, Formik } from 'formik'
import { Answer } from '../../redux/quiz/slice'
import { LogoDivider } from '../core/bars/LogoDivider'
import { BackIconButton } from '../core/buttons/BackIconButton'
import { ScreenProps } from '../types'
import { FormattedMessage } from 'react-intl'

export type FormValues = { [key: string]: string }
export type FormValidationSchema = any | (() => any)

export interface FormProps {
  body: React.ReactNode
  initialValues?: FormValues
  validationSchema?: FormValidationSchema
}

export interface FormScreenProps extends ScreenProps {
  headerBar?: React.ReactNode
  screenTitle: React.ReactNode
  headlineExplanation?: React.ReactNode
  description?: React.ReactNode
  descriptionBelowForm?: React.ReactNode // only for email screen
  dividerSectionLogo?: string
  dividerSectionText?: React.ReactNode
  goalAnswersToBeSubmitted?: Answer[]
  buttonText?: React.ReactNode
  forceEnableButton?: boolean // only for half-way screen where there's no form rendered
  hideButton?: boolean
  showHomepageLink?: boolean
  form: FormProps
}

export const FormScreen: React.FC<FormScreenProps> = ({
  headerBar,
  screenTitle,
  headlineExplanation,
  description,
  descriptionBelowForm,
  dividerSectionLogo,
  dividerSectionText,
  goalAnswersToBeSubmitted,
  buttonText,
  forceEnableButton,
  hideButton,
  showHomepageLink,
  form,

  onNextClick,
  onBackClick,
}) => {
  const { body, initialValues = {}, validationSchema } = form

  return (
    <div>
      {headerBar}
      <div className="flex items-center justify-center align-center">
        {onBackClick && (
          <div className="flex items-center w-form-width-mobile sm:w-form-width-desktop sm:justify-start mt-8">
            <BackIconButton onClick={onBackClick} />
            <p className="text-zoe-primary" onClick={onBackClick} style={{ cursor: 'pointer' }}>
              Back
            </p>
          </div>
        )}
      </div>
      <main className="flex flex-row flex-1 w-full justify-center bg-background">
        <div
          className="flex w-form-width-mobile flex-col justify-between p-6 sm:w-form-width-desktop sm:justify-start"
          style={{ boxSizing: 'initial' }}
        >
          <h2 className="text-left">{screenTitle}</h2>
          {headlineExplanation && <h4 className="mt-8 mb-6">{headlineExplanation}</h4>}
          {description && <p className="light text-base-secondary mt-4 sm:mt-6">{description}</p>}

          {form && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                goalAnswersToBeSubmitted ? onNextClick(values, goalAnswersToBeSubmitted) : onNextClick(values)
                setSubmitting(false)
              }}
            >
              {({ isSubmitting, isValid, dirty }) => (
                <Form className="mt-6 sm:mt-8">
                  {body}
                  {descriptionBelowForm && (
                    <p className="light text-base-secondary mt-6 sm:mt-8">{descriptionBelowForm}</p>
                  )}
                  {dividerSectionLogo && (
                    <div className="mt-10">
                      <LogoDivider logo={dividerSectionLogo} />
                      <p className="light text-base-tertiary text-center mt-1">{dividerSectionText}</p>
                    </div>
                  )}
                  <div className="mt-10 mb-12 text-center">
                    {!hideButton && (
                      <Button type="submit" disabled={!(forceEnableButton || (isValid && dirty)) || isSubmitting}>
                        {buttonText || <FormattedMessage id="next" />}
                      </Button>
                    )}
                    {showHomepageLink && !(isValid && dirty) && (
                      <a
                        href={`${appConfig.zoeWebsiteBaseUri}`}
                        className="mt-4 block text-zoe-primary"
                        data-testid="homepage-link"
                      >
                        Return to homepage
                      </a>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </main>
    </div>
  )
}
