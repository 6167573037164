import snakecase from '@stdlib/string-snakecase'
import { appConfig } from 'appConfig'
import { Configuration, EventIn, LeadEventApi } from 'clients/lead-tracking'
import { requestWithRetry } from './requestWithRetry'

export type TrackEvent = {
  eventName: string
  source?: string
  zoeLeadId: string
  quizId?: string
  checkoutEmail?: string
  quizEmail?: string
  cookieConsent?: boolean
  data?: any
}

type TrackEventKey = keyof TrackEvent

export class LeadTrackingException extends Error {}
export class LeadTrackingValidationException extends LeadTrackingException {}

const config = new Configuration()
const getLeadTracker = (): LeadEventApi => new LeadEventApi(config, appConfig.leadTrackingApiBaseUri)

// Converts TrackEvent (camelCase) into EventIn (snake_case)
const asEventIn = (event: TrackEvent): EventIn => {
  const eventIn: Partial<EventIn> = {}
  Object.keys(event).forEach((key) => {
    eventIn[snakecase(key) as keyof EventIn] = event[key as TrackEventKey]
  })
  return eventIn as EventIn
}

const DEFAULT_EVENT: Partial<TrackEvent> = {
  source: process.env.REACT_APP_LEAD_TRACK_SOURCE,
}

const isValidEvent = (event: TrackEvent): boolean => {
  if (!event.zoeLeadId) {
    throw new LeadTrackingValidationException('ZOE Lead Id cannot be empty')
  }

  if (!event.eventName) {
    throw new LeadTrackingValidationException('Event name cannot be empty')
  }

  return true
}

export const track = async (event: TrackEvent, leadTracker = getLeadTracker()): Promise<void> => {
  const trackedEvent: TrackEvent = {
    ...DEFAULT_EVENT,
    ...event,
  }
  if (isValidEvent(trackedEvent)) {
    const response = await requestWithRetry(() => leadTracker.append(asEventIn(trackedEvent)))
    if (response.status !== 201) {
      throw new LeadTrackingException(`Unexpected response from lead-tracking service: ${response.status}`)
    }
  }
}
