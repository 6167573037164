import React, { useMemo } from 'react'
import { FormScreen, FormValidationSchema, FormValues } from 'components/screen-templates/FormScreen'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { Quizzes } from 'redux/quiz/types'
import { InputField } from 'components/core/fields/InputField'
import { lowerHealthyBMIWeightForHeightForUnit } from 'libs/weight/weightCalculations'
import * as Yup from 'yup'
import { MAX_ALLOWED_WEIGHT_KG, MAX_ALLOWED_WEIGHT_LBS } from 'libs/weight/types'
import { Answer } from 'clients/quiz-service'
import { IntlShape, useIntl } from 'react-intl'

export type TargetWeightScreenProps = {
  title: string
  descriptionLbs: string
  descriptionKg: string
  buttonText?: string
}

type Props = TargetWeightScreenProps & {
  onBackClick?: onBackClickCallback
  onNextClick: onNextClickType
  quiz: Quizzes
}

export const TargetWeightScreen: React.FC<Props> = ({
  quiz,
  title,
  descriptionLbs,
  descriptionKg,
  buttonText,
  onNextClick,
  onBackClick,
}) => {
  const intl = useIntl()

  const handleOnNextClick = (arg1?: FormValues | Answer[] | any) => {
    const args = arg1 || {}
    if (args.weight_unit === 'lbs') {
      onNextClick({ ideal_weight_lbs: args.ideal_weight_lbs })
    } else {
      onNextClick({ ideal_weight_kg: args.ideal_weight_kg })
    }
  }

  return (
    <FormScreen
      screenTitle={title}
      buttonText={buttonText}
      description={quiz.weight_unit === 'lbs' ? descriptionLbs : descriptionKg}
      form={{
        body: (
          <>
            <input type="hidden" name="weight_unit" />

            <InputField
              placeholder={intl.formatMessage(
                { id: 'quiz.ideal_weight.weight_field.label' },
                { unit: quiz.weight_unit },
              )}
              name={quiz.weight_unit === 'lbs' ? 'ideal_weight_lbs' : 'ideal_weight_kg'}
              type="number"
              step="0.1"
            />
          </>
        ),
        validationSchema: validationSchema(intl, quiz),
        // needed to prevent React "uncontrolled" error showing
        initialValues: { ideal_weight_lbs: '', ideal_weight_kg: '', weight_unit: quiz.weight_unit },
      }}
      onNextClick={handleOnNextClick}
      onBackClick={onBackClick}
    />
  )
}

const validationSchema = (intl: IntlShape, quiz: Quizzes): FormValidationSchema => {
  const minWeightLBS = useMemo(() => lowerHealthyBMIWeightForHeightForUnit(quiz, 'lbs'), [quiz])
  const minWeightKG = useMemo(() => lowerHealthyBMIWeightForHeightForUnit(quiz, 'kg'), [quiz])

  return Yup.object({
    weight_unit: Yup.string(),
    ideal_weight_lbs: Yup.number().when('weight_unit', {
      is: (weight_unit) => weight_unit === 'lbs',
      then: Yup.number()
        .required(intl.formatMessage({ id: 'quiz.ideal_weight.weight_field.error' }))
        .min(
          minWeightLBS.value,
          intl.formatMessage(
            {
              id: 'quiz.ideal_weight.validation.lower_bmi_limit',
            },
            { weight: minWeightLBS.value, unit: 'lbs' },
          ),
        )
        .max(
          MAX_ALLOWED_WEIGHT_LBS,
          intl.formatMessage(
            { id: 'quiz.weight_validation.max_weight' },
            { maxWeight: MAX_ALLOWED_WEIGHT_LBS, unit: 'lbs' },
          ),
        ),
      otherwise: Yup.number(),
    }),
    ideal_weight_kg: Yup.number().when('weight_unit', {
      is: (weight_unit) => weight_unit === 'kg',
      then: Yup.number()
        .required(intl.formatMessage({ id: 'quiz.ideal_weight.weight_field.error' }))
        .min(
          minWeightKG.value,
          intl.formatMessage(
            {
              id: 'quiz.ideal_weight.validation.lower_bmi_limit',
            },
            { weight: minWeightKG.value, unit: 'kg' },
          ),
        )
        .max(
          MAX_ALLOWED_WEIGHT_KG,
          intl.formatMessage(
            { id: 'quiz.weight_validation.max_weight' },
            { maxWeight: MAX_ALLOWED_WEIGHT_KG, unit: 'kg' },
          ),
        ),
      otherwise: Yup.number(),
    }),
  })
}
