import {
  COOKIE_DOMAIN_MAP,
  QUIZ_FLOW_COOKIE_NAME,
  QUIZ_ID_COOKIE_EXPIRY_DAYS,
  QUIZ_ID_COOKIE_NAME,
  STAGE_COOKIE_DOMAIN,
} from 'components/constants'
import { useQuizService } from 'contexts/QuizServiceContext'
import React, { useEffect, useMemo } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { selectQuiz, selectQuizCountry, updateQuiz } from 'redux/quiz/slice'
import { QuizVersion } from 'redux/quiz/types'
import { parseLandingRedirectPageQueryParams } from '../webflow'

export interface EntryScreenProps extends RouteComponentProps {
  to: string
  quizVersion: QuizVersion
}

export const cookieExpiryDate = (numberOfDays: number = QUIZ_ID_COOKIE_EXPIRY_DAYS): Date =>
  new Date(Date.now() + numberOfDays * 24 * 60 * 60 * 1000)
export const cookieDomain =
  COOKIE_DOMAIN_MAP[window.location.hostname as keyof typeof COOKIE_DOMAIN_MAP] || STAGE_COOKIE_DOMAIN

export const EntryScreen: React.FC<EntryScreenProps> = ({ to, location, quizVersion }) => {
  const quiz = useSelector(selectQuiz)
  const dispatch = useDispatch()
  const { quizApi } = useQuizService()
  const quizCountry = useSelector(selectQuizCountry)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie] = useCookies([QUIZ_ID_COOKIE_NAME, QUIZ_FLOW_COOKIE_NAME])

  const { flow } = useMemo(() => parseLandingRedirectPageQueryParams(location), [location])

  // Needed so that all GTM datalayers have cUserId cookie set
  const setQuizIdAndFlowCookies = () => {
    // Purposely overwrite value even if cookie already exists, so it matches whatever is in Redux state
    if (quiz.id) {
      setCookie(QUIZ_ID_COOKIE_NAME, quiz.id, { path: '/', expires: cookieExpiryDate(), domain: cookieDomain })
    }

    if (flow) {
      setCookie(QUIZ_FLOW_COOKIE_NAME, flow, { path: '/', expires: cookieExpiryDate(), domain: cookieDomain })
    }
  }

  const updateResults = async () => {
    const answers: { question_key: string; answer: string }[] = [{ question_key: 'quiz_version', answer: quizVersion }]

    if (flow) {
      answers.push({ question_key: 'landing_page_selection', answer: flow })
    }

    if (location.search) {
      answers.push({ question_key: 'query_params', answer: location.search })
    }

    try {
      await quizApi.answers({
        user_id: quiz.id,
        quiz_version: quizVersion,
        answers,
        quiz_country: quizCountry,
      })

      dispatch(
        updateQuiz({
          quiz: { landing_page_selection: flow },
          version: quizVersion,
        }),
      )
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    setQuizIdAndFlowCookies()
    updateResults()
  }, [quizApi])

  return <>{quizCountry ? <Redirect to={{ pathname: to, search: location?.search }} /> : null}</>
}
