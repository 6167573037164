import React from 'react'
import { appConfig } from '../../appConfig'

type Props = {
  hideBorder?: boolean
}

export const LogoHeader: React.FC<Props> = ({ hideBorder }) => {
  const borderClasses = hideBorder ? '' : 'border-b-2 border-solid border-background-tertiary'
  return (
    <div className={`h-logo-header flex justify-center items-center ${borderClasses}`}>
      <div className="flex justify-star w-form-width-mobile sm:w-form-width-desktop sm:justify-start">
        <img src={`${appConfig.publicUrl}/img/logos/zoe-logo.svg`} alt="ZOE logo" className="h-zoe-tm-logo" />
      </div>
    </div>
  )
}
